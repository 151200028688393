import React from "react";
import { BlogPost } from "components";

export default function Blog() {
  return (
    <div>
      <h1 className="text-center text-white display-1">COMING SOON</h1>
      {/* <div>
        <BlogPost />
      </div> */}
    </div>
  );
}
